@media(min-width: 1100px) {
	body .admin .edit-page.editar-evento .header + .admin-bloque {
		grid-template-columns: 1fr 2fr !important;
	}
}

@media(min-width: 1300px) {
	body .admin .edit-page.editar-evento .header + .admin-bloque {
		grid-template-columns: 4fr 3fr !important;
	}
}

@media(min-width: 1500px) {
	body .admin .edit-page.editar-evento .header + .admin-bloque {
		grid-template-columns: 5fr 3fr !important;
	}
}

.admin .edit-page.editar-evento .descripcion {
	height: 300px;
}

.admin .edit-page.editar-evento .precio {
	margin-left: 0.35em;
}

.admin .edit-page.editar-evento select {
	border: 1px solid #d7e0e2;
	background: white;
	margin: 0 0 0 0.35em;
	height: 36px;
    margin-top: -2px;
}

.admin .edit-page.editar-evento .toggles {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 20px 0;
}

@media(max-width: 1500px) {
    .admin .edit-page.editar-evento .toggles {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0;
    }

    .admin .edit-page.editar-evento .toggles > div {
        width: 100%;
        justify-content: space-between;
    }
}

.admin .edit-page.editar-evento .toggles > div {
	display: flex;
	align-items: center;
}

.admin .edit-page.editar-evento .toggles .form-toggle {
	margin-left: 0.35em;
}

.admin .edit-page.editar-negocio .json-editor .plus-wide,
.admin .edit-page.editar-evento .accordion-tab-container {
	margin-left: 0;
	margin-right: 0;
}

.admin .edit-page.editar-evento .day-select,
.admin .edit-page.editar-evento .day-select a:first-child {
	margin-left: 0;
}

.admin .edit-page.editar-evento .day-select {
	margin-bottom: 25px;
}

.admin .edit-page.editar-evento .timetable-editor {
	margin-left: 5px;
}

.admin .edit-page.editar-evento .timetable-editor .buttons {
	display: flex;
	align-items: center;
	position: relative;
}

.admin .edit-page.editar-evento .timetable-editor .buttons > div {
	cursor: pointer;
	user-select: none;
}

.admin .edit-page.editar-evento .timetable-editor .buttons .plus {
	position: absolute;
	right: 0;
	bottom: 7px;
}

.admin .edit-page.editar-evento .timetable-editor .buttons img {
	filter: invert(1) brightness(0.5);
	padding: 6px;
	width: 30px;
	height: 30px;
	border: 1px solid black;
	border-radius: 15px;
	margin-right: 10px;
	display: block;
}

.admin .edit-page.editar-evento .timetable-editor .buttons > div:hover img {
	filter: none;
}

.admin .edit-page.editar-evento .timetable-editor .slot {
	display: flex;
	align-items: center;
}

.admin .edit-page.editar-evento .day-select.disabled,
.admin .edit-page.editar-evento .timetable-editor.disabled {
	pointer-events: none;
	opacity: 0.5;
}

.admin .edit-page.editar-evento .delete-row {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 15px;
	cursor: pointer;
}

.admin .edit-page.editar-evento .programar-container {
	position: relative;
}

.admin .edit-page.editar-evento .programar-container .programar-enabled {
	position: absolute;
	right: 0;
	top: 20px;
}

.admin .edit-page.editar-evento .programar-disabled-notice {
    position: absolute;
    z-index: 1;
    background: #ffffffdd;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 20px;
    top: calc(50% + 28px);
    transform: translateY(-50%);
    animation: programar-disabled-notice 300ms forwards;
}

@keyframes programar-disabled-notice {
    0% {
        opacity: 0;
        transform: translateY(15px);
    }
    100% {
        opacity: 1;
        transform: translateY(-50%);
    }
}

.admin .edit-page.editar-evento h2.programar-disponibilidad {
	margin-top: 15px;
	margin-left: -20px;
    margin-right: -20px;
    border-top: 1px solid lightgrey;
    padding-top: 10px;
    padding-left: 20px;
}

.admin .edit-page.editar-evento .error h2.programar-disponibilidad {
    color: crimson;
}

.admin .edit-page.editar-evento .timetable-editor .heading {
	display: flex;
    align-items: center;
}

.admin .edit-page.editar-evento .timetable-editor .heading > div,
.admin .edit-page.editar-evento .timetable-editor .slot > * {
	width: 35%;
	max-width: 176px;
}

.admin .edit-page.editar-evento .timetable-editor .input-select {
	width: 25%;
	max-width: 126px;
}

.admin .edit-page.editar-evento .timetable-editor .delete-row {
	width: 1em;
}

.admin .edit-page.editar-evento .admin-bloque.encuestas .columna {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 4fr);
}

.admin .edit-page.editar-evento .admin-bloque.encuestas .columna .category {
    padding: 15px 20px;
    background-color: #f6f6f6;
}

@media(max-width: 1550px) {
    .admin .edit-page.editar-evento .admin-bloque.encuestas .columna {
        grid-template-columns: repeat(3, 3fr);
    }
}

.admin .edit-page.editar-evento .estadisticas .bar-chart.chart-container > div {
	display: flex;
	flex-direction: column;
	margin-top: 2em;
}

.admin .edit-page.editar-evento .individual-subscription-form .custom-field .number-field-container {
	justify-content: flex-end;
}

.admin .edit-page.editar-evento .individual-subscription-form label {
	font-weight: normal;
	font-size: 0.9em;
}

.admin .edit-page.editar-evento .individual-subscription-form {
	display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 5px;
	padding: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-bottom: 10px;
	align-items: center;
}

.admin .edit-page.editar-evento .subscription-data-container .pack-tag {
	margin-left: 1em;
    background: #333;
    color: #fafafa;
    padding: 1px 8px 3px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 0.9em;
}

.admin .edit-page.editar-evento .individual-subscription-form .form-toggle {
	display: inline-flex;
	margin-left: 1em;
}


.admin .edit-page.editar-evento .subscription-data-container {
	border-top: 1px solid lightgrey;
    margin: 20px -20px 0;
    padding: 10px 20px 0;
}

.admin .edit-page.editar-evento .subscription-data-container select.error {
	border: 1px solid crimson;
}

.admin .edit-page.editar-evento .subscription-data-container .type-row {
	display: flex;
	justify-content: space-between;
	font-size: 0.9em;
    padding: 5px 0;
}

.admin .edit-page.editar-evento .add-new-container {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.admin .edit-page.editar-evento .add-new-container select {
	margin-left: 0;
}

.admin .edit-page.editar-evento .page-content-editor {
    position: relative;
    padding-top: 80px;
}

.admin .edit-page.editar-evento .page-content-editor:empty {
    padding-top: 50px;
}

.admin .edit-page.editar-evento .page-content-editor .sections .columns .section .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin: 0 -20px 20px;
    border-bottom: 1px solid lightgrey;
}

.admin .edit-page.editar-evento .page-content-editor .columns {
    display: flex;
    justify-content: space-evenly;
    margin: 0 -20px;
    padding: 0;
    border-bottom: 1px solid lightgrey;
}

.admin .edit-page.editar-evento .page-content-editor .columns:last-child {
    border-bottom: 0;
    margin-bottom: -20px;
}

.admin .edit-page.editar-evento .page-content-editor .columns .section {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 20px;
}

.admin .edit-page.editar-evento .page-content-editor .columns .section {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 5px;
    margin-right: 5px;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
}

.admin .edit-page.editar-evento .page-content-editor .columns .section:first-child {
    margin-left: 0;
    border-left: none;
}

.admin .edit-page.editar-evento .page-content-editor .columns .section:last-child {
    margin-right: 0;
    border-right: none;
}

.admin .edit-page.editar-evento .page-content-editor .image-upload.empty {
    min-height: 64px;
}

.admin .edit-page.editar-evento .page-content-editor .icons-container .image-upload {
    display: inline-flex;
}

.admin .edit-page.editar-evento .page-content-editor .icons-container > div {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}

.admin .edit-page.editar-evento .page-content-editor .icons-container > div .delete-icon {
    display: none;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    position: absolute;
    right: -12px;
    top: -12px;
    background-color: white;
    border: 1px solid #333;
    z-index: 1;
    font-size: 1.5em;
}

.admin .edit-page.editar-evento .page-content-editor .icons-container > div:hover .delete-icon {
    display: flex;
}

.admin .edit-page.editar-evento .page-content-editor .icons-container > div:hover .delete-icon:hover {
    color: white;
    background-color: #333;
}

.admin .edit-page.editar-evento .page-content-editor .show-cta {
    display: flex;
    padding-top: 1em;
    margin-left: auto;
}

.admin .edit-page.editar-evento .page-content-editor .show-cta .form-toggle {
    margin-right: 0.5em;
}

.admin .edit-page.editar-evento .page-content-editor .section-faq {
    display: grid;
    grid-template-columns: 2fr 2fr;
}

.admin .edit-page.editar-evento .page-content-editor .faq-item {
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    position: relative;
}

.admin .edit-page.editar-evento .page-content-editor .faq-item .delete-faq {
    display: none;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    position: absolute;
    right: -6px;
    top: -6px;
    background-color: white;
    border: 1px solid #333;
    z-index: 1;
    font-size: 1.5em;
}

.admin .edit-page.editar-evento .page-content-editor .faq-item:hover .delete-faq {
    display: flex;
}

.admin .edit-page.editar-evento .page-content-editor .faq-item .delete-faq:hover {
    background-color: #333;
    color: white;
}

.admin .edit-page.editar-evento .page-content-editor .faq-item:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}

.admin .edit-page.editar-evento .page-content-editor .faq-item textarea,
.admin .edit-page.editar-evento .page-content-editor .faq-item input {
    width: 100%;
}

.admin .edit-page.editar-evento .page-content-editor .inner-actions {
    position: absolute;
    top: 0;
    right: 0;
}

.admin .edit-page.editar-evento .page-content-editor .new-section {
    display: block;
    margin-top: 20px;
    border-top: 1px solid lightgrey;
    border-radius: 0 0 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-top-color: lightgrey;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
}

.admin .edit-page.editar-evento .page-content-editor .new-section:hover {
    background-color: #fafafa;
}

.admin .edit-page.editar-evento .page-content-editor input,
.admin .edit-page.editar-evento .page-content-editor textarea {
    border: 1px solid lightgrey;
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 10px;
}

.admin .edit-page.editar-evento .page-content-editor label {
    font-weight: bold;
}

.admin .edit-page.editar-evento .page-content-editor .table-row > span > select,
.admin .edit-page.editar-evento .page-content-editor .table-row > span > input {
    margin: 0;
    padding: 3px 5px;
}

.admin .edit-page.editar-evento .page-content-editor .columna:first-child {
    padding-top: 0;
}