#main > .admin {
	display: flex;
}

#main > .admin .content {
	padding-bottom: 100px;
	margin-left: 250px;
	position: relative;
}

body.bg-grey #main {
	background: #f6f6f6;
}

body.bg-grey #main > .admin .content {
	padding-bottom: 0;
	margin-bottom: 100px;
}

.admin .sidebar {
	display: flex;
	flex-direction: column;
	border-right: 1px solid lightgrey;
	width: 250px;
	position: fixed;
	left: 0;
	bottom: 0;
	background: white;
	z-index: 2;
	overflow: auto;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}

.admin .sidebar::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}

.admin .sidebar .top,
.admin .sidebar .middle,
.admin .sidebar .bottom {
	display: flex;
	flex-direction: column;
}

.admin .sidebar .top {
	border-bottom: 1px solid lightgrey;
}

.admin .sidebar .bottom {
	margin-top: auto;
}

.admin .sidebar a {
	padding: 15px 20px 15px 20px;
	font-size: 15px;
	display: flex;
	align-items: center;
}

.admin .sidebar .menu-desplegable .title {
	padding: 15px 20px 15px 20px;
	font-size: 15px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.admin .sidebar .menu-desplegable .title:hover {
	background: var(--resaltado-claro);
}

#main > .admin .menu-desplegable .content,
body.bg-grey #main > .admin .menu-desplegable .content {
	margin: 0;
	border-left: 15px solid var(--resaltado-claro);
	padding: 0;
}

.admin .sidebar .menu-desplegable.collapsed .content {
	display: none;
}

@media (max-height: 890px) {
	.admin .sidebar a {
		padding: 10px 20px 10px 20px;
	}
}

.admin .sidebar .material-symbols-rounded {
	margin-right: 8px;
}

.admin .sidebar a.active {
	color: black;
	background: lightgrey;
}

.admin .sidebar a.active:hover {
	background: lightgrey;
	color: black;
	cursor: default;
}

.admin .sidebar a i {
	box-sizing: border-box;
	width: 40px;
	font-size: 1.5em;
}

.admin .sidebar a:hover {
	background: var(--resaltado-claro);
}

.admin .content {
	padding: 0;
	flex: 1;
}

.admin .list.container {
	margin-left: 0;
	font-size: 13px;
	max-width: calc(100% - 100px);
}

.admin .list .col {
	display: flex;
	align-items: center;
	position: relative;
	white-space: nowrap;
	text-overflow: clip;
}

.admin .list .col.clip {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.admin .list .button {
	position: absolute;
	right: 0;
	background: white;
	z-index: 1;
	padding: 5px;
}

.admin .list .col.narrow {
	max-width: 75px;
}

.admin .form-input input:not([type="checkbox"]),
.form-input textarea {
	font-size: 13px;
	padding-top: 3px;
}

.admin .modal-content-area .form-input {
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 5px;
	margin-top: 5px;
}

.admin .modal-content-area .form-input label {
	top: 0;
}

.admin .list .form-input.select {
	font-size: 13px;
}

.admin .list .form-input.select .options-list {
	box-shadow: 0 0 30px rgb(0 0 0 / 10%);
}

.admin-home .admin-bloque .container {
	padding-left: 0;
	padding-right: 0;
}

.admin-home .admin-bloque:first-of-type {
	background: white;
}

.admin .admin-home .form-input.type-text {
	margin: 30px 0;
}

.admin .admin-home .form-input label {
	top: 0;
}

.admin .admin-home .form-input input.non-empty + label {
	top: -16px;
	left: 7px;
	color: grey;
}

.admin .admin-home .logos {
	margin: 25px 0;
}

.admin .admin-home .logos .image-upload {
	height: 85px;
}

.admin .admin-home .logos img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}

.admin .admin-home .logos-home-2 .image-upload {
	padding: 25px;
	box-sizing: border-box;
}

.admin .admin-faq .group .form-input {
	margin-top: 25px;
}

.admin .admin-faq .form-input label {
	top: 0;
}

.admin .faq-row {
	position: relative;
}

.admin .faq-row .delete-button {
	position: absolute;
	right: 0;
	top: 40px;
	z-index: 1;
}

.admin .negocios .event-text.event-info > div {
	display: flex;
	align-items: center;
}

.admin .negocios .event-text.event-info > div img {
	margin-right: 10px;
}

.admin .negocios .event-text.event-info > div .form-input {
	flex: 1;
}

.admin .usuario.row {
	border-bottom: 1px solid #eaeaea;
	padding-bottom: 20px;
	margin-bottom: 20px;
	margin-left: -20px;
	margin-right: -20px;
}

.admin .usuario.row:last-child {
	border-bottom: none;
	padding-bottom: 0;
	margin-bottom: 0;
}

.admin .edit-page .col {
	margin-bottom: 0.5em;
}

.admin .edit-page label {
	padding: 5px 0;
	margin: 0;
	font-weight: bold;
}

.admin select {
	padding: 5px;
}

.admin .edit-page .save-button {
	float: right;
}

.admin-pagina-html .trumbowyg-editor,
.admin-pagina-html .trumbowyg-textarea {
	min-height: 600px;
}

.trumbowyg-button-pane {
	background: white;
	border-color: white;
}

.admin-home .trumbowyg-editor,
.admin-home .trumbowyg-textarea {
	min-height: 185px;
	height: 185px;
}

.admin-home .trumbowyg-box {
	min-height: unset;
}

.admin-home .image-upload {
	background-size: 50%;
}

.admin-home .logos-home-1,
.admin-home .logos-home-3 {
	margin-top: 17%;
}

.admin-page .header,
.admin .edit-page .header {
	height: 78px;
	background: white;
	padding: 10px 103px 10px 25px;
	text-transform: uppercase;
	border-bottom: 1px solid lightgrey;
	margin: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

body.scrolled header.admin {
	box-shadow: none;
}

body.scrolled .admin-page .header,
body.scrolled .admin .edit-page .header {
	position: fixed;
	z-index: 3;
	right: 0;
	left: 250px;
}

body.scrolled.admin-header .admin .edit-page {
	padding-top: 83px;
}

.admin .edit-page .header h2 {
	text-transform: uppercase;
	margin: 0 auto 0 0;
}

.admin-page .header .btn {
	border: 1px solid #292929;
	font-size: 0.8rem;
	width: 192px;
}

@media (max-width: 1050px) {
	.admin-page .header .btn {
		width: 160px;
	}
}

.admin-page .header .btn-small {
	margin: 0;
}

.admin-page .header h2 {
	margin: 0;
}

.admin-page .header .left {
	justify-self: flex-start;
	margin-right: auto;
}

.admin .edit-page .header .material-symbols-rounded {
	font-size: 36px;
	margin-top: 5px;
}

.admin .edit-page .header .enabled-status {
	height: 42px;
}

.admin .narrow-content {
	max-width: 920px;
	margin-left: auto;
	margin-right: auto;
}

.admin .admin-bloque.columnas.video-bloque {
	padding-top: 50px;
}

.admin .admin-bloque.columnas.video-bloque.disabled .columna {
	filter: opacity(0.5) grayscale(1);
	pointer-events: none;
}

.admin .video-toggle-container {
	position: absolute;
	right: 0;
	padding: 15px 30px;
	display: flex;
	justify-content: flex-end;
}

.admin .video-columna,
.admin .video-poster-columna {
	display: flex;
	align-items: center;
}

.admin .image-upload.error,
.admin .input-select.error,
.admin .custom-field.error textarea,
.admin .custom-field.error input {
	border-color: crimson;
	box-shadow: 0px 0px 2px crimson;
}
