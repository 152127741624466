.dot-menu .menu:not(.active) {
    display: none;
}

.dot-menu .dots {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 28px;
    justify-content: space-between;
    position: relative;
    width: 28px;
    background: white;
    border-radius: 14px;
    padding: 3px 0;
}

.dot-menu.active .dots {
    filter: invert(1);
}

.dot-menu .dots > div {
    background: #333;
    border-radius: 3px;
    height: 4px;
    margin-bottom: auto;
    margin-top: auto;
    width: 4px;
}

.dot-menu .menu {
    position: fixed;
    z-index: 1;
    background: white;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
}

.dot-menu .menu a {
    padding: 15px;
    display: block;
}

.dot-menu .menu a:hover {
    background: var(--resaltado);
    color: white;
}