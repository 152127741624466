.subscriptions-enabled .home header.desktop {
    border-bottom: 1px solid lightgrey;
}

.subscriptions-enabled .home .top-list {
    padding-top: 20px;
}

.subscriptions-enabled .home .widget-filtro {
    padding-left: 0;
    padding-right: 0;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1020;
    animation: none;
    transition: 300ms;
}

.subscriptions-enabled .home .widget-filtro > a {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 15px;
    padding: 5px 25px;
}

.subscriptions-enabled .home .widget-filtro .boton-cantidad.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.subscriptions-enabled .home h2.selected-subscription-name {
	font-size: 19px;
	margin-bottom: 10px;
	opacity: 0.5;
	color: var(--resaltado);
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 25px;
}

.subscriptions-enabled .home .subscription-description {
    max-width: min(1200px, calc(100vw - 50px));
    margin: 0 auto;
    border: 1px solid lightgrey;
    font-size: 13px;
    padding: 10px;
    margin-top: 20px;
}

.subscriptions-enabled .home .subscription-description.no-subscriptions {
    font-size: 16px;
    text-align: center;
    margin: 25px auto 34vh;
}

.subscriptions-enabled .home .subscription-description > div::after {
    display: block;
    content: "";
    clear: both;
}

.subscriptions-enabled .home .subscription-description > div {
    max-width: 66vw;
    margin: 0 auto;
    position: relative;
}

.subscriptions-enabled .home .subscription-description.no-subscriptions > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.subscriptions-enabled .home .subscription-description.no-subscriptions > div > div {
    flex: 1;
}

.subscriptions-enabled .home .subscription-description .cta {
    float: right;
    width: 120px;
    display: block;
    text-align: center;
    font-weight: 700;
    font-family: "Circular";
    font-size: 0.8rem;
    text-transform: uppercase;
    margin: 0 6px 0 2em;
    padding: 1.25rem 1.5rem;
}

.subscriptions-enabled .home .subscription-description.no-subscriptions .cta {
    margin-left: auto;
    width: auto;
}

.mis-suscripciones .subscription-type:not(.subscribed) {
    opacity: 0.5;
    pointer-events: none;
}

.subscriptions-enabled header .navbar .navbar-nav .nav-link.suscripciones {
    background: var(--gris-texto);
    color: white;
    height: 60px;
    display: flex;
    align-items: center;
}

.subscriptions-enabled header .navbar .navbar-nav .nav-link.suscripciones:hover {
    color: var(--gris-texto);
}

.subscriptions-enabled .subscription-info-boxes {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    margin: 100px auto 50px;
    max-width: 1400px;
    border-radius: 50px;
    border: 1px solid lightgrey;
    padding: 15px;
}

.subscriptions-enabled .subscription-info-boxes > div {
    box-sizing: border-box;
    display: flex;
    flex: 0.5;
    flex-direction: column;
    justify-content: flex-start;
    padding: 50px;
}

.subscriptions-enabled .subscription-info-boxes > div.image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 40px;
}

.subscriptions-enabled .subscription-info-boxes h2 {
    font-size: 40px;
    letter-spacing: 1px;
    line-height: 1;
    margin-top: 1rem;
    padding-top: .5rem;
}

.subscriptions-enabled .image-container .out-of-stock {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ea5555;
    color: white;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 13px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.subscriptions-enabled.subscriptions-bar-visible .widget-filtro {
    opacity: 1;
}

@media(max-width: 1250px) {
    .subscriptions-enabled .home h2.selected-subscription-name,
    .subscriptions-enabled .home .subscription-description {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media(max-width: 1450px) {
    .subscriptions-enabled .subscription-info-boxes {
        margin-left: 24px;
        margin-right: 24px;
    }
}

@media(max-width: 992px) {
    .subscriptions-enabled .home .widget-filtro {
        top: 86px;
        z-index: 1025;
    }

    .subscriptions-enabled .home .subscription-description {
        margin-top: 30px;
    }

    .subscriptions-enabled .subscription-info-boxes > div.image {
        min-height: 25vh;
    }
}